
import { latLng, icon } from 'leaflet';
import zapier from '../assets/img/icons/zapier.png';
// import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from 'vue2-leaflet';

export default {
  name: 'Example',
  // components: {
  //   LMap,
  //   LTileLayer,
  //   LMarker,
  //   LPopup,
  //   LTooltip
  // },
  data() {
    return {
      zoom: 13,
      center: latLng(47.41322, -1.219482),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true,
      icon: icon({
        iconUrl: zapier,
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      })
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert('Click!');
    }
  }
};
